<template>
  <base-panel
    id="knowledge-base"
    icon="mdi-tag-text"
    :title="$t('know.title') + ' - ' + project.name + ' (' + language + ')'"
    v-if="tags"
  >
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>

    <div v-show="edit_mode">
      <v-textarea
        v-model="edit_entry"
        filled
        :label="$t('know.edit entry')"
      ></v-textarea>
      <v-select
        v-model="selected_tags"
        :items="tags"
        item-text="tag"
        item-value="id"
        chips
        multiple
        color="primary"
        :label="$t('know.tags')"
      >
        <template v-slot:prepend-item>
          <div class="mx-2">
            <v-text-field
              v-model="new_tag"
              prepend-icon="mdi-tag-plus"
              :label="$t('know.add tag')"
              @change="addTag(new_tag)"
            ></v-text-field>
          </div>
        </template>
      </v-select>

      <v-alert color="red" v-if="edit_error">{{ edit_error }}</v-alert>
      <div class="text-end">
        <v-btn color="grey" type="submit" @click="cancelEdit" class="mr-2">
          <v-icon>mdi-close</v-icon> {{ $t("Cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          @click="saveEntry"
          :disabled="!this.edit_entry || !this.selected_tags.length"
        >
          <v-icon>mdi-content-save</v-icon> {{ $t("Save") }}
        </v-btn>
      </div>
    </div>

    <div v-show="!edit_mode">
      <div v-if="show_entries">
        <v-card
          class="entry"
          v-for="entry in entries"
          :key="'entry' + entry.id"
          @mouseover="hover_entry = entry.id"
          @mouseleave="hover_entry = 0"
        >
          <v-card-text>
            <div @click="useText(entry.text)">
              {{ entry.text }}
            </div>
            <v-btn
              v-if="hover_entry == entry.id && delete_tags"
              color="error"
              class="add-kb"
              fab
              dark
              small
              style="float:right"
              @click="deleteEntry(entry.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              v-if="hover_entry == entry.id"
              color="secondary"
              class="add-kb"
              fab
              dark
              small
              style="float:right"
              @click="editEntry(entry)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </div>

      <div>
        <v-text-field
          v-model="search"
          prepend-icon="search"
          :label="$t('know.search')"
          @change="getEntries(search)"
        ></v-text-field>
        <v-chip
          v-for="tag in tags"
          :key="'tag' + tag.id"
          :close="delete_tags"
          class="ma-1"
          dark
          @click="getEntries(tag.id)"
          @click:close="deleteTag(tag.id)"
          >{{ tag.tag }}</v-chip
        >
        <v-spacer></v-spacer>
        <div class="text-end">
          <v-btn
            v-show="tags.length"
            :color="delete_tags ? 'error' : 'primary'"
            fab
            dark
            small
            @click="toggleDelete"
            :title="$t('know.delete mode')"
          >
            <v-icon>mdi-tag-remove</v-icon></v-btn
          >
        </div>
      </div>
    </div>
  </base-panel>
</template>

<script>
export default {
  props: {
    language: {
      type: String,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tags: [],
      entries: [],
      show_entries: false,
      delete_tags: false,
      loading: false,
      edit_mode: false,
      edit_entry: "",
      edit_id: null,
      edit_error: null,
      search: "",
      selected_tags: [],
      new_tag: "",
      hover_entry: 0,
    };
  },
  mounted() {
    this.getTags();
  },
  watch: {
    language() {
      this.show_entries = false;
      this.entries = [];
      this.tags = [];
      this.loading = true;
      this.getTags();
    },
  },
  methods: {
    getTags() {
      this.get("/kb/tags", {
        project_id: this.project.id,
        language: this.language,
      })
        .then((data) => {
          this.tags = data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toggleDelete() {
      this.delete_tags = !this.delete_tags;
    },
    getEntries(tag) {
      this.loading = true;
      this.entries = {};
      this.get("/kb/entries", {
        project_id: this.project.id,
        language: this.language,
        tags: [tag],
      })
        .then((data) => {
          this.entries = data;
          this.show_entries = true;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deleteTag(tag) {
      this.delete("/kb/tags/", tag, {
        project_id: this.project.id,
        language: this.language,
      }).then(() => {
        this.getTags();
      });
    },
    addTag(tag) {
      for (let i in this.tags) {
        if (this.tags[i].tag == tag) {
          this.selected_tags.push(this.tags[i].id);
          this.new_tag = "";
          return;
        }
      }
      try {
        this.tags.push({ id: tag, tag: tag });
        this.selected_tags.push(tag);
      } catch (e) {}
      this.new_tag = "";
    },
    useText(text) {
      this.$emit("text", text);
    },
    addToKb(text) {
      this.edit_mode = true;
      this.edit_entry = text;
      this.edit_id = null;
      this.selected_tags = [];
      this.new_tag = "";
    },
    editEntry(entry) {
      this.edit_mode = true;
      this.edit_entry = entry.text;
      this.edit_id = entry.id;
      this.selected_tags = [];
      for (let i in entry.tags) {
        this.selected_tags.push(entry.tags[i].id);
      }
      this.new_tag = "";
    },
    cancelEdit() {
      this.edit_mode = false;
      this.edit_entry = "";
      this.edit_id = null;
      this.selected_tags = [];
      this.new_tag = "";
    },
    saveEntry() {
      this.loading = true;
      this.edit_error = null;
      if (!this.edit_entry || !this.selected_tags.length) {
        return;
      }
      if (this.edit_id) {
        this.patch("/kb/entries/" + this.edit_id, {
          project_id: this.project.id,
          language: this.language,
          text: this.edit_entry,
          tags: this.selected_tags,
        })
          .then(() => {
            this.cancelEdit();
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.edit_error = err.message;
          });
      } else {
        this.post("/kb/entries/", {
          project_id: this.project.id,
          language: this.language,
          text: this.edit_entry,
          tags: this.selected_tags,
        })
          .then(() => {
            this.cancelEdit();
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.edit_error = err.message;
          });
      }
    },
    deleteEntry(entry) {
      this.delete("/kb/entries/", entry, {
        project_id: this.project.id,
        language: this.language,
      }).then(() => {
        for (let i in this.entries) {
          if (this.entries[i].id == entry) {
            this.$vue.delete(this.entries, i);
            break;
          }
        }
      });
    },
  },
};
</script>

<style>
#knowledge-base {
  background: rgba(202, 186, 139, 0.3);
}
#knowledge-base .panel-content {
  height: calc(100vh - 280px);
  overflow-y: auto;
}
</style>

<style>
.entry {
  cursor: pointer;
}
.entry:hover {
  background-color: #c2b38640;
}
.add-kb {
  margin-top: -15px;
}
</style>
